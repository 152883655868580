import { SIZES } from "src/constants"
import { css } from "styled-components"

export default css`
  body {
    font-size: ${SIZES.regular}px;
    &.noscroll {
      width: 100%;
      position: fixed;
    }
  }
  button {
    border: none;
    background: none;
    font-size: ${SIZES.medium}px;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  input,
  button {
    outline: none;
  }
  a {
    text-decoration: none;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
  h1,
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.4em;
    font-weight: 600;
    margin: 8px 0;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`
