// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-associations-tsx": () => import("./../../../src/pages/associations.tsx" /* webpackChunkName: "component---src-pages-associations-tsx" */),
  "component---src-pages-benevolat-tsx": () => import("./../../../src/pages/benevolat.tsx" /* webpackChunkName: "component---src-pages-benevolat-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-association-tsx": () => import("./../../../src/templates/association.tsx" /* webpackChunkName: "component---src-templates-association-tsx" */),
  "component---src-templates-highlight-tsx": () => import("./../../../src/templates/highlight.tsx" /* webpackChunkName: "component---src-templates-highlight-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

