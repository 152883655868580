import { InMemoryCacheConfig } from "@apollo/client"

export const APOLLO_CACHE_OPTIONS: InMemoryCacheConfig = {
  typePolicies: {
    Association: {
      keyFields: ["id", "absolute"],
    },
  },
}

export const COLORS = {
  white: "#ffffff",
  black: "#000000",
  bright: "#f2f2f2",
  grey: "#494848",
  greyDark: "#141414",
  blue: "#007b8c",
  red: "#CC0000",
  yellow: "#ffe45f",
  // Form
  placeholder: "#999999",
  inputBorder: "#e7e7e7",
}

export const SIZES = {
  medium: 16,
  buttons: 15,
  regular: 14,
  small: 13,
  tiny: 11,
}

export const LAYOUT = {
  width: 1000, // 960 + (2 x padding)
  tablet: 800,
}

export const RESULTS_PER_PAGE = 18

export const DOMAIN_ID = process.env.GATSBY_DOMAIN_ID
